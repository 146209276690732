@import 'mixins';

.header {
	padding: 3.2rem 4.2rem 0 4.7rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	backdrop-filter: blur(10px);

	@include responsive-on-width(980px) {
		padding: 3.2rem 1.7rem 0 1.7rem;
	}


	&__hide {
		transform: translateY(-100%);
	}

	&__show {
		transform: translateY(0%);
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 102;
	}

	&__transition {
		transition: all 0.8s ease;
	}

	&__logo {
		width: 10.3rem;
		height: 2.8rem;
		transform: translateY(-50%);

		&--img {
			width: 100%;
			height: 100%;
		}
	}

	&__right {
		transform: translateY(-42%);

		@media (max-width: 990px) {
			transform: translateY(-130%);
		}

		&--container {
			display: flex;
			align-items: center;
			gap: 1rem;
		}

		@media (max-width: 990px) {
			&--container {
				display: none;
			}
		}


		&--lang {
			color: #BCBCBC;
			background-color: rgba(255, 255, 255, 0.2);
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			display: inline-block;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 0.6rem 1.2rem;
			gap: 1rem;
			border-radius: 40%;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				background-color: rgba(255, 248, 248, 0.468);
			}
		}

		&--signin {
			color: var(--color-white);
			font-size: 1.4rem;
			line-height: 1.6rem;
			padding: 1.1rem 2.3rem;
			border-radius: 3.8rem;
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				border-bottom-width: 10%;
				// width: 100%;

				background: rebeccapurple;
			}
		}
	}

	&__hamburger {
		display: none;
		flex-direction: column;
		gap: 0.6rem;
		cursor: pointer;

		&--1,
		&--2,
		&--3 {
			width: 2.5rem;
			height: 2px;
			background-color: #BCBCBC;
			transform: translateX(-20%);
			transition: all 300ms ease;
			transition-delay: 300ms;
			transform: scaleX(100%);
		}

		&--2 {
			transform-origin: left 0;
			transform-style: preserve-3d;
		}

		&--active {
			.header__hamburger {
				&--1 {
					transform: translate3d(0px, 3px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}

				&--2 {
					transform: scaleX(0);
				}

				&--3 {
					transform: translate3d(0px, -5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-40deg) skew(0deg, 0deg);
					transform-style: preserve-3d;
				}
			}
		}


		@media (max-width: 990px) {
			display: flex;
		}
	}


	&__navigation {
		// background-color: #6c4098;
		// background-color: rgba(36, 35, 35, 0.963);
		backdrop-filter: blur(20px);
		position: fixed;
		// right: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		left: -100%;
		transition: left 0.8s ease-in-out;
		// transition-delay: 50ms;
		overflow: hidden;

		&-items {
			margin-top: 10rem;
			display: flex;
			gap: 3rem;
			flex-direction: column;
			justify-content: center;
			// align-items: center;

			ul li {
				font-weight: 100;
				font-size: 2.4rem;
				line-height: 1.1;
				color: rgba(194, 193, 177, 0.855);
				margin-bottom: 5px;
				margin-left: 2.6rem;
				padding: 2rem;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 2rem;


				svg {
					width: 25px;
					height: 25px;
					transition: fill 200ms ease-in-out 200ms;
					// fill: #FC9790;
				}

				&:hover {
					color: rgba(255, 254, 241, 0.855);
					// border-left: 0.5px solid #BCBCBC;
					// padding-left: 3rem;

					svg {
						fill: #6c4098;
					}
				}
			}
		}

		&--in {
			left: 0;
		}

		&--logo {
			display: block;
			// width: 10.2rem;
			margin-top: auto;
			margin: 4rem auto 0 auto;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	&__left-side {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.9rem;
		width: 100%;

		@include responsive-on-width(980px) {
			flex-direction: row-reverse;
			width: unset;
		}
	}
}

.btn-start {
	display: none;
	transform: translateY(-37%);

	.button__secondary {
		background: unset;
	}

	@include responsive-on-width(980px) {
		display: inline-block;
	}

	@include responsive-on-width(328px) {
		transform: translateY(-46%);
		.button__secondary {
			line-height: 1rem;
			padding: 1rem 1.7rem;
		}
	}

	@include responsive-on-width(315px) {
		display: none;
	}
}