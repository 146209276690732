@mixin responsive-on-width($size) {
	@media only screen and (max-width: $size) {
		@content;
	}
}

@mixin center-flex-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@mixin mobileLandscape {
	@media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (orientation: landscape) {
		@content;
	}
}