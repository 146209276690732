@import 'mixins';

.mainc {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	// width: 80vw;

	justify-content: space-between;
	gap: 12.5rem;



	@include responsive-on-width(1024px) {
		// flex-direction: column;
		width: 100%;
		margin: 10.1rem 0;
		gap: unset;
	}

	.listCards {
		align-items: flex-start;
		margin-left: 3.4rem;
	}
}

canvas {
	position: sticky;
	left: 50%;
	top: 25%;
	// transform: translate(0, -50%);
	// max-width: 50vw; // resize later
	// max-height: 100vh;

	max-width: 44vw;
	max-height: 49vh;
	z-index: 10;

	@include responsive-on-width(1024px) {
		top: 12%;
		transform: translateX(-24%);
	}
}