@import 'mixins';

.termsCondition {
	&__head {
		text-align: center;
		margin-bottom: 2rem;

		h3 {
			font-size: 1.5rem;
		}
	}

	&__container {
		margin-bottom: 2rem;

		h3 {
			font-size: 1.9rem;
		}

		p,
		ul>li {
			font-weight: 300;
			font-size: 1.8rem;
			line-height: 1.5;
		}

		ul>li {
			margin-left: 2rem;
			list-style-type: disc;

		}


		@include responsive-on-width(520px) {
			h3 {
				font-size: 1.7rem;
			}

			p,
			ul>li {
				font-size: 1.5rem;
				line-height: 1.4;
			}
		}
	}
}