@import 'mixins';

.section2 {
	display: flex;
	flex-direction: column;
	gap: 21.9rem;
	margin-bottom: 23.4rem;
	align-items: center;

	@media only screen and (max-width: 1100px) {
		gap: 0;
		margin-bottom: 7.941rem;
		// overflow: hidden;

		&::before {
			// content: '';
		}
	}

	// &::before { // should be tweaked on media query
	// 	width: calc(100% + 4.2rem);
	// 	height: 48vh;
	// 	position: sticky;
	// 	top: 0;
	// 	left: 0;
	// 	background-color: var(--background-color);
	// 	z-index: 9;
	// }

	&__top {
		color: #FFF;
		display: flex;
		justify-content: space-between;

		@media (max-width: 1290px) {
			// flex-direction: column-reverse;
		}


		@media only screen and (max-width: 1100px) {
			position: static;
			flex-direction: column-reverse;
			max-width: 100%;
		}

		&--left {
			display: flex;
			justify-content: center;
			height: fit-content;

			@include responsive-on-width(1100px) {
				margin-top: 3rem;
				margin-bottom: 4.7rem;
				@include center-flex-column;
				gap: 3rem;
			}
		}

		&--right {
			width: 100%;

			@media only screen and (max-width: 1100px) {
				// scale: 1.456;
				margin-top: 7.3rem;
			}

			@media only screen and (min-width: 1580px) {
				width: 780px;
			}

			@include responsive-on-width(350px) {
				max-width: 90%;

			}
		}
	}

	&__image {
		width: 100%;
		height: auto;

	}

	.features {
		position: relative;
		width: 434px;
		align-self: flex-start;
		height: 100%;
		overflow-y: hidden;
		// transform: translateY(50%);

		// @media only screen and (max-width: 1100px) {
		// 	transform: none;
		// }

		@media (max-width: 585px) {
			width: 100%;
			word-break: break-word;
			margin: 10rem 0;
		}

		&__text {
			display: inline-block;

			@media (max-width: 525px) {
				width: 60vw;
			}
		}


		&__index {
			color: red;
		}

		&__border {
			background-color: #246BFD;
			width: 1px;
			display: inline;
			position: absolute;
			top: 1%;
			left: 0;
			height: 100%;

			@media only screen and (max-width: 1100px) {
				width: 2px;
				top: unset;
				height: 6.6%;
			}

			&--xtra {
				opacity: 1;
				position: absolute;
				width: 1px;
				height: 12%;
				backdrop-filter: blur(10px);
				top: 1%;
				left: 0;
			}
		}

		&__count {
			color: #E7E7E7;
			font-weight: 400;
			font-size: 24px;
			line-height: 28.25px;
			background: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			margin: 0 2rem;
			display: inline-block;
			transform: translateY(100%);


			@media (max-width: 525px) {
				// margin: 0 2rem 0 0;
				font-size: 16px;
				line-height: 17.25px;
				transform: translateY(180%);
			}

			@media (max-width: 332px) {
				margin: 0 2rem;
			}
		}

		&__list {
			display: flex;
			justify-content: center;
			margin: 21rem 0;
			opacity: 0.4;

			@media (max-width: 1100px) {
				margin: 3rem 0
			}

			// @media (max-width: 525px) {
			// 	margin: 0;
			// }
		}


		h3 {
			font-weight: 600;
			font-size: 32px;
			line-height: 38px;
			color: #E7E7E7;



			@media (max-width: 525px) {
				font-size: 20px;
				line-height: 32px;
			}

		}

		p {
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: rgba(231, 231, 231, 0.9);
			margin: 1.7rem 0 15rem 0;
			hyphens: auto;

			@media only screen and (max-width: 1100px) {
				margin-top: 1.7rem;
			}

			@media (max-width: 525px) {
				font-size: 17px;
				line-height: 25px;
			}
		}
	}

	&__bottom {
		max-width: 823px;
		margin: auto;
		text-align: center;
		z-index: 15;

		@include responsive-on-width(580px) {
			max-width: 25rem;
		}

		@include responsive-on-width(300px) {
			padding: 0 2.6rem;
		}

		// @media (max-width: 912px) {
		// 	text-align: left;
		// 	margin-top: 2rem;
		// }

		p {
			font-weight: 400;
			font-size: 24px;
			line-height: 28.25px;
			color: rgba(231, 231, 231, 0.6);


			@media (max-width: 600px) {
				font-weight: 600;
				font-size: 1.7rem;
				line-height: 2.2rem;
			}

			@include responsive-on-width(1100px) {
				padding: 0 2.6rem;
			}
		}
	}
}

// Move from bottom to view
.move-bottom-to-view {
	animation-name: move-in-Y;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}

@keyframes move-in-Y {
	from {
		transform: translateY(100%);
	}

	to {
		transform: translateY(0);
	}
}

// Move out of view (above)
.move-view-above {
	animation-name: move-in-out-Y;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}

@keyframes move-in-out-Y {
	from {
		transform: translateY(0%);
	}

	to {
		transform: translateY(-100%);
	}
}

// Move view below
.move-view-bottom {
	animation-name: move-out-Y;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}

@keyframes move-out-Y {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(100%);
	}
}


// Move from above to view
.move-top-to-view {
	animation-name: move-out-in-Y;
	animation-duration: 3s;
	animation-fill-mode: forwards;
}

@keyframes move-out-in-Y {
	from {
		transform: translateY(-100%);
	}

	to {
		transform: translateY(0%);
	}
}

.visible {
	animation-name: addOpacity;
	animation-duration: 900ms;
	animation-delay: 200ms;
	animation-fill-mode: forwards;
}

.opaque {
	animation-name: removeOpacity;
	animation-fill-mode: forwards;
}

@keyframes removeOpacity {
	from {
		opacity: 1
	}

	to {
		opacity: 0.4
	}
}

@keyframes addOpacity {
	from {
		opacity: 0.4;
	}

	to {
		opacity: 1;
	}
}


.listCards {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
	width: 100%;
}

// align-items: flex-start;
// margin-left: 3.4rem;

// move-bottom-to-view
// move-view-above
// move-view-bottom
// move-top-to-view