@import 'mixins';

.section5 {
	margin-top: 16.6rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include responsive-on-width(1100px) {
		margin: 11.6rem 0 3rem 0;
	}

	&__header {
		// margin-bottom: 9.3rem;
		max-width: 77.5rem;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;

		h4 {
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #e7e7e7;
			margin-bottom: 4.3rem;

			@include responsive-on-width(1100px) {
				font-size: 3.6rem;
				line-height: 4.2rem;
				text-align: center;
				margin-bottom: 2rem;
			}

			// @media only screen and (max-width: 1290px) {
			// 	font-weight: 400;
			// 	font-size: 3.8rem;
			// 	line-height: 4.7rem;
			// }

			// @media only screen and (max-width: 950px) {
			// 	margin-bottom: 1rem;
			// }

			// @media only screen and (max-width: 760px) {
			// 	font-size: 4.2rem;
			// 	line-height: 4.2rem;
			// }

			// @media only screen and (max-width: 680px) {
			// 	font-weight: 100;
			// 	font-size: 3.1rem;
			// 	line-height: 3.2rem;
			// }

			// @media only screen and (max-width: 580px) {
			// 	font-weight: 100;
			// 	font-size: 2.1rem;
			// 	line-height: 2.2rem;
			// 	text-align: left;
			// }

		}

		p {
			font-weight: 400;
			font-size: 24px;
			line-height: 28px;
			text-align: center;
			color: rgba(255, 255, 255, 0.5);
			margin-bottom: 9.3rem;

			@include responsive-on-width(1100px) {
				font-size: 1.7rem;
				line-height: 2rem;
			}

			// @media only screen and (max-width: 580px) {
			// 	text-align: left;
			// }
		}
	}


	&__cards {
		display: flex;
		justify-content: center;
		align-items: baseline;
		gap: 3.1rem;

		@media only screen and (max-width: 980px) {
			flex-direction: column;
			align-items: center;
			gap: 9rem;

			.feature {
				margin: 0 auto;

				&__info {
					text-align: left;

					p {
						white-space: pre-wrap;
					}
				}
			}
		}
	}

	.carousel .carousel-item .feature {
		@include responsive-on-width(355px) {
			max-width: unset;
		}
	}
}