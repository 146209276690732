@import 'mixins';
.App {
	background-color: var(--background-color);
	// min-height: 100vh;
	min-height: -webkit-fill-available;
	padding: 0 9.7rem 16.3rem 14.1rem;

	@include responsive-on-width(1100px) {
		padding: 0
	}
	// @include responsive-on-width(820px) {
	// 	// padding: 0 4.2rem 2rem 4.2rem;
	// 	padding: 0
	// }
}

.section4,
.section5,
.section6,
.footer {
	// width: 100%;
	overflow: hidden;
}