.carousel {
  overflow: hidden;

	.inner {
		white-space: nowrap;
		transition: transform 0.3s;
	}

	&-item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-right: 2.7rem;
		// height: 200px;
		// background-color: green;
		// color: #fff;
	}

	.indicators {
		display: flex;
		justify-content: center;
	}

	.indicators > button {
		margin: 5px;
	}

	
	button {
		&.active,
		&.inactive {
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 50%;
			border: 0;
		}
		&.active {
			background-color: #246BFD;
		}
	
		&.inactive {
			background-color: rgba(36, 107, 253, 0.5);
		}
	}
}





// .indicators > button.active {
//   // background-color: green;
//   color: #fff;
// }
