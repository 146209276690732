@import 'mixins';

.signup {
	position: fixed;
	z-index: 110;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--modal-covering-background-color);
	// display: block;
	justify-content: center;
	align-items: center;

	scale: 0;
	opacity: 0;
	visibility: hidden;

	&__form {
		position: relative;
		margin: 30px;
		min-width: 220px;
		max-width: 475px;
		color: #FFF;
		z-index: 2;
		top: 28%;
		left: 50%;
		transform: translateX(-56.5%);


		@include responsive-on-width(395px) {
			top: 20%;
		}

		&--container {
			margin: 20px;
			display: flex;
			flex-direction: row;
			background-color: #212121;
			padding: 1rem 1rem 2rem 1rem;
			border-radius: 1.5rem;
		}


		&--background {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #212121;
			border-radius: 16px;
			z-index: -1;
			box-shadow: 0 10px 40px rgb(0 0 0 / 30%);
		}

		&--wrapper {
			margin-left: 8px;
		}

		&--close {
			cursor: pointer;
			height: fit-content;
		}

		&-message {
			opacity: 0;
			display: none;
			transition: all 0.3s
		}

		&-message p {
			font-size: 1.5rem;
		}

	}

	&__heading {
		font-size: 24px;
		line-height: 1.5;
		margin: 0;
		max-width: calc(100% - 60px);
	}

	&__info {
		font-weight: 400;
		line-height: 2.1;
		font-size: 1.5rem;

	}


	&__message-container {
		text-align: left;
	}

	&-stars {
		width: 36px;
		margin-right: 8px;
	}

	&__email-field {
		border-radius: 5px;
		width: 100%;
		margin-bottom: 10px;
		color: var(--white);
		background-color: #505050;
		padding: 12px 12px 12px 24px;
	}

	&__button {
		border-radius: 5px;
		width: 30%;
		min-width: 110px;
		margin-left: auto;
		color: var(--white);
		margin-top: 16px;
		background: var(--button-background);
		display: flex;
		justify-content: center;
		align-items: center;
		height: 44px;
		font-size: 16px;
		padding: 0 24px;
		text-align: center;
		border: 0;
		cursor: pointer;
		transition: all 0.3s;

		&:hover {
			background: #236af6cc;
		}

		&:active {
			scale: 0.89
		}
	}


	&--show {
		animation-name: visible;
		animation-duration: 300ms;
		animation-fill-mode: forwards;
		// overflow: hidden;
	}

	&--close {
		animation-name: invisible;
		animation-duration: 800ms;
		animation-fill-mode: forwards;
	}
}


.input-reset {
	display: block;
	box-sizing: border-box;
	height: 44px;
	padding: 12px;
	font-size: 16px;
	text-align: left;
	border: none;
	-webkit-appearance: none;
}

.message-show {
	opacity: 1;
	display: inline-block;
	animation-name: visible;
	animation-duration: 800ms;
	animation-fill-mode: forwards;
}

input:focus-visible {
	outline-offset: 0px;
}

input[type="email"]:focus-visible {
	outline: -webkit-focus-ring-color auto 1px;
}

@keyframes visible {

	from {
		scale: 0;
		opacity: 0;
		visibility: hidden;
	}

	to {
		scale: 1;
		opacity: 1;
		visibility: visible;
	}
}

@keyframes invisible {

	from {
		scale: 1;
		opacity: 1;
		visibility: visible;
	}

	to {
		scale: 0;
		opacity: 0;
		visibility: hidden;
	}
}