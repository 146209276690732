@import 'mixins';

video {
	width: 100%;
	object-fit: cover;
	height: 100%;
	object-position: bottom;
}

.hero {
	// background-image: url('../../assets/video-backup.png');
	// height: 100vh;
	// width: 100vw;
	// background-position: center;
	height: 100vh;

	&__bg-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__bg-video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		opacity: 0.15;
		overflow: hidden;

		&--content {
			z-index: -1;
			width: 100%;
			height: 100%;
			object-fit: cover;

			// @media (max-width: 810px) {
			// 	// max-width: fit-content;
			// 	object-fit: cover;
			// 	// object-fit: fill;
			// 	overflow: hidden;
			// 	// height: 70%;
			// }
		}
	}

	&__info {
		position: absolute;
		// top: 30rem;
		// left: 50%;
		// transform: translateX(-50%);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		gap: 3.1rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include mobileLandscape {
			top: 58%;
			gap: 0.5rem;
		}


		.button__primary {
			transition: all 0.3s;
		}

		.button__primary:hover {
			background-color: #FFFFFF;
			color: #1D0070;
		}

		@media (max-width: 1024px) {
			width: 80%;
		}

		@media (max-width: 478px) {
			width: 90%;

			.button__primary {
				margin-top: 1rem;
				font-size: 1.3rem;
				padding: 14px 34px;
			}
		}

	}

	&__button,
	&__text,
	&__heading {
		color: #fff;
	}

	&__heading {
		font-weight: 600;
		font-size: 5.6rem;
		line-height: 6.7rem;
		text-align: center;

		@media (max-width: 760px) {
			font-weight: 400;
			font-size: 3.6rem;
			line-height: 4.3rem;
		}
		
		@include mobileLandscape {
			line-height: 4.295rem;
			font-size: 3.6rem;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 2.4rem;
		line-height: 2.8rem;
		text-align: center;
		color: rgba(231, 231, 231, 0.6);

		@media (max-width: 700px) {
			font-size: 1.7rem;
			line-height: 2rem;
			// margin-top: 1rem;
		}

		@include mobileLandscape {
			font-size: 1.7rem;
			line-height: 2rem;
		}
	}
}