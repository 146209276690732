@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&family=Rubik:ital,wght@1,800&display=swap');

.section3 {
  min-height: 100vh;
  position: relative;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  max-height: 8000px;
  max-width: 2000px;
  margin: 0 auto;

  @media screen and (max-width: 1100px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 15px;
  }

  &__feature-image-container {
    position: relative;
    height: 100%;
    grid-column: 2;
    @media screen and (max-width: 800px) {
      margin-top: 300px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 200px;
    }
    @media screen and (max-width: 400px) {
      margin-top: 120px;
    }
  }
  &__feature-image-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 85%;
    @media screen and (max-width: 800px) {
      height: 700px;
    }
    @media screen and (max-width: 500px) {
      height: 500px;
    }
    @media screen and (max-width: 400px) {
      height: 400px;
    }
  }

  &__feature-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    height: 81%;
    width: 90%;

    z-index: 2;

    > img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 800px) {
      height: 675px;
    }
    @media screen and (max-width: 500px) {
      height: 475px;
    }
    @media screen and (max-width: 400px) {
      height: 380px;
    }
  }

  &__heading {
    color: #e7e7e7;
    max-width: 60.7rem;
    margin: auto;
    text-align: center;
    word-break: break-word;
    position: sticky;
    top: 30px;

    h2 {
      margin: auto;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;

      @include responsive-on-width(1100px) {
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 4.295rem;
        margin-bottom: 0;
        padding: 0 60px;
      }

      // @include responsive-on-width(1100px) {
      // 	margin-bottom: 5.6rem;
      // }

      // @include responsive-on-width(760px) {
      // 	font-size: 4.2rem;
      // 	line-height: 4.2rem;
      // }

      // @include responsive-on-width(680px) {
      // 	font-weight: 100;
      // 	font-size: 3.1rem;
      // 	line-height: 3.2rem;
      // }

      // @include responsive-on-width(580px) {
      // 	font-weight: 100;
      // 	font-size: 2.1rem;
      // 	line-height: 2.2rem;
      // 	word-break: break-all;
      // }
    }
  }

  &__features {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    width: 100%;
    max-height: 1000px;
    max-width: 175rem;
    margin: 0 auto;

    @include responsive-on-width(1100px) {
      margin: 0;
      align-items: center;
      gap: 3rem;
    }

    .infoCard {
      flex-direction: row;
      word-break: break-all;

      &__head {
        display: flex;
        flex-direction: column;
        gap: 9px;
      }

      @include mobileLandscape {
        flex-direction: row;
        width: auto;
        max-width: 90%;
      }

      @include responsive-on-width(1024px) {
        width: auto;
        max-width: 90%;
      }

      @include responsive-on-width(880px) {
        flex-direction: column;
        align-items: center;
      }
      @include responsive-on-width(480px) {
        flex-direction: column;
        width: 34.1rem;
        max-width: 80%;
        padding: 30px;
      }
      @include responsive-on-width(380px) {
        padding: 20px;
      }

      &__image-container {
        @include responsive-on-width(1024px) {
          flex: 0.5;
        }

        @include responsive-on-width(480px) {
          flex: unset;
        }
      }

      &__image {
        @include responsive-on-width(1024px) {
          margin-top: -45%;
        }
        @include responsive-on-width(880px) {
          max-width: 280px;
        }

        @include responsive-on-width(480px) {
          margin-top: -31%;
        }
      }
    }
  }

  &__feature-heading {
    padding-bottom: 1.7rem;
    padding-top: 4.5rem;
  }

  &__feature {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    gap: 12rem;
    position: relative;
    padding: 0 30px;

    &--reverse {
      flex-direction: row-reverse;
      justify-content: center;
    }

    @include responsive-on-width(1300px) {
      display: flex;
      & {
        gap: 0;
      }

      &,
      &--reverse {
        margin-left: 0;
        justify-content: center;
        flex-direction: row-reverse;
        gap: 50px;
        align-items: center;
      }
    }

    &--info {
      width: 33.6rem;
      position: absolute;
      // right: 22vw;

      @include responsive-on-width(1300px) {
        position: relative;
        right: -4%;
        left: auto !important;
      }

      @include responsive-on-width(430px) {
        width: 100%;
      }

      &::after {
        content: attr(data-value);
        color: #e7e7e7;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 28.25px;
        background: radial-gradient(
          102.94% 100% at 72.83% 100%,
          #ffb8e0 0%,
          #be9eff 38.89%,
          #88c0fc 67.4%,
          #86ff99 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    span,
    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #e7e7e7;
      width: 100%;
      word-break: break-word;
      .typed-cursor {
        font-size: 32px;
        font-weight: 400;
        font-family: 'Raleway', sans-serif;
        font-weight: 100;
      }

      @media (max-width: 525px) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    h3 {
      padding-bottom: 1.7rem;
      padding-top: 4.5rem;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: rgba(231, 231, 231, 0.9);
      word-break: break-word;

      @media only screen and (max-width: 700px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-top: 1rem;
      }

      @media only screen and (max-width: 580px) {
        text-align: left;
      }
    }
  }
}

.iphone-container {
  width: 28.1rem;
  height: 56.8rem;
  margin: 0 auto;
  position: sticky;
  top: 10%;
  margin: auto;
  display: block;

  .screen-1.slide-out,
  .screen-2.slide-out,
  .screen-3.slide-out {
    animation-name: moveY;
    animation-duration: 3s;
    // animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .screen-1.slide-in,
  .screen-2.slide-in,
  .screen-3.slide-in {
    animation-name: moveInY;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }

  @keyframes moveInY {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes moveY {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }
}

.iphone {
  width: 28.1rem;
  height: 56.8rem;
  // background-image: url('../../assets/blankiPhone.png');
  position: relative;
  overflow: hidden;
  z-index: 5;
  border-radius: 50px;

  .iphoneBlank {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
  }

  &--page {
    width: 100%;
  }
}

.iphoneHead {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
}

.screen-1,
.screen-2,
.screen-3,
.screen-4 {
  position: absolute;
  top: 2.2%;
  left: 3%;
  margin-left: 2%;
  transform: translateY(0);
}

.screen-1 {
  z-index: 4;
}

.screen-2 {
  z-index: 3;
}

.screen-3 {
  z-index: 2;
}

.screen-4 {
  z-index: 1;
}

.abs--l0,
.abs--r0 {
  top: 0;
  position: relative;

  @include responsive-on-width(1100px) {
    transform: none;
  }
}

.abs--l0 {
  grid-column: 1;
  grid-row: 1;
}

.abs--r0 {
  grid-column: 3;
  grid-row: 1;
}

.section3__typing-end {
}

.section-wrapper {
  perspective: 1000px;
}

.section3__feature {
}

.section3-features-wrapper {
  position: sticky;
  top: 0;
}
.section3-feature-wrapper {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 85%;
  display: flex;
  justify-content: center;

  @include responsive-on-width(1100px) {
    padding: 0 50px;
  }
  @include responsive-on-width(500px) {
    padding: 0 20px;
  }
}

.section3-responsive-feature {
  height: 100%;
  display: flex;

  .infoCard {
    height: 100%;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 800px) {
      display: flex;
    }
  }
}

.section3-bar {
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 1200px) {
    right: -4%;
  }
  @media screen and (min-width: 1700px) {
    right: 0;
  }
  &__content {
    background-color: rgba(255, 255, 255, 0.136);
    width: 4px;
    border-radius: 12px;
    height: 100%;
    position: relative;
    overflow: hidden;

    &__active {
      position: absolute;
      height: 20%;
      background-color: #246bfd;
      width: 100%;
      border-radius: 12px;
    }
  }
}

.section-bar-sm {
  right: -40px;

  @media screen and (max-width: 1100px) {
    right: 0;
  }
  @media screen and (min-width: 1700px) {
    right: -30px;
  }
}
