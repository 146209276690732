@import 'mixins';

.infoCard {
	background-color: #181834;
	@include center-flex-column;
	gap: 2.1rem;
	align-items: flex-start;
	padding: 4rem 4rem 4rem 3rem;
	border-radius: 1.8rem;
	width: 34.1rem;
	max-width: 80%;
	overflow: hidden;

	@include responsive-on-width(300px) {
		width: 96%;
	}
	
	&__head {
		flex: 0.5;
	}

	&__heading {
		text-align: left;
		font-weight: 600;
		font-size: 3.2rem;
		line-height: 3.8rem;
		color: #FFF;

		@include responsive-on-width(350px) {
			word-break: break-word;
		}
	}

	&__text {
		// margin-top: 2.1rem;
		font-weight: 600;
		font-size: 1.7rem;
		line-height: 2.2rem;
		color: rgba(255, 255, 255, 0.5);
		word-break: break-word;
	}

	&__image-container {
		transform: translateY(27%);

		@include mobileLandscape {
			flex: 0.5;
		}
	}
	&__image {
		width: 100%;
		margin-top: -31%;

		@include mobileLandscape {
			margin-top: -45%;
		}
	}
}