@font-face {
	font-family: "Gilroy";
	src: url('./fonts/Gilroy/Gilroy-Regular.woff') format('woff');
}

@font-face {
	font-family: "Gilroy";
	src: url('./fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: "Gilroy";
	src: url('./fonts/Gilroy/Gilroy-Medium.woff') format('woff');
	font-weight: 400;
}


:root {
	--background-color: #000000;
	--white: #FFF;
	--font-family: 'Gilroy', sans-serif;
	--color-dark: #000;
	--color-white: #FFFFFF;
	--color-grey: #E7E7E7;
	--color-transparent: rgba(0, 0, 0, 0);
	--font-family: 'Gilroy', sans-serif;
	--button-background: #246BFD;
	--button-border: 1px solid #246BFD;
	--text-background-gradient: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
	--card-gradient-pimary: linear-gradient(180deg, #6141D5 0%, rgba(93, 65, 213, 0.38) 100%);
	--card-gradient-secondary: linear-gradient(180deg, #3B1DA6 0%, rgba(93, 65, 213, 0.38) 100%);
	--modal-covering-background-color: rgba(0, 0, 0, 0.3);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	outline: none;
}

html {
	font-size: 62.5%;
	/* =10px */
	scroll-behavior: smooth;
}

body {
	background-color: var(--color-background);
	font-family: var(--font-family);
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}



.button__primary {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 18px 37px;
	gap: 10px;
	background-color: var(--button-background);
	border-radius: 16.25rem;
	width: max-content;
	font-style: normal;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 1.9rem;
	text-align: center;
	text-transform: uppercase;
	color: #FFFFFF;
	margin-top: 1.2rem;
	cursor: pointer;
	border: 0;
}


.button__secondary {
	color: var(--color-white);
	font-size: 1.4rem;
	line-height: 1.6rem;
	padding: 1.1rem 2.3rem;
	border: var(--button-border);
	border-radius: 10rem;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		background-color: rebeccapurple;
		border: 1px solid rebeccapurple;
	}
}


.gradient {
	&--number {
		width: 4.1rem;
		height: 2.8rem;
		font-family: 'Gilroy';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;

		/* Градиент */
		background: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
	}

	&--text {
		// height: 57px;
		font-family: 'Gilroy';
		font-style: normal;
		font-weight: 600;
		font-size: 48px;
		line-height: 57px;
		text-align: center;

		/* Градиент */
		background: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
	}
}

.spin {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}