@import 'mixins';

.section6 {
	margin-top: 13.1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include responsive-on-width(1100px) {
		margin-top: 7.2rem;
	}

	&__info {

		@include responsive-on-width(1100px) {
			margin-left: 3.3rem;
			margin-right: 3.3rem;
			margin-bottom: 0;
		}
	}

	h3 {
		margin-bottom: 4.3rem;

		@include responsive-on-width(1100px) {
			font-weight: 600;
			font-size: 36px;
			line-height: 43px;
			text-align: center;
			margin-bottom: 2rem;

			// &.gradient--text {
			// 	background: radial-gradient(102.94% 100% at 72.83% 100%, #FFB8E0 0%, #BE9EFF 38.89%, #88C0FC 67.4%, #86FF99 100%);
			// 	-webkit-background-clip: text;
			// 	-webkit-text-fill-color: transparent;
			// 	background-clip: text;
			// 	text-fill-color: transparent;
			// }
		}

		// @include responsive-on-width(1290px) {
		// 	font-weight: 400;
		// 	font-size: 3.8rem;
		// 	line-height: 4.7rem;
		// }

		// @include responsive-on-width(950px) {
		// 	margin-bottom: 1rem;
		// }


		// @include responsive-on-width(760) {
		// 	font-size: 4.2rem;
		// 	line-height: 4.2rem;
		// }

		// @include responsive-on-width(680px) {
		// 	font-weight: 100;
		// 	font-size: 3.1rem;
		// 	line-height: 3.2rem;
		// }


		// @include responsive-on-width(580px) {
		// 	font-weight: 100;
		// 	font-size: 2.1rem;
		// 	line-height: 2.2rem;
		// 	text-align: left;
		// }
	}

	p {
		width: 74.8rem;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);

		@include responsive-on-width(1100px) {
			font-size: 1.7rem;
			line-height: 2rem;
			width: unset;
		}
	}

	&__download {
		margin: 5.7rem 0 13.2rem 0;
		display: flex;
		gap: 3rem;

		@include responsive-on-width(1100px) {
			margin: 5.072rem 0 7.1rem 0;
			flex-direction: column;
			gap: 2.98rem
		}

		img {
			width: 15.8rem;
			height: auto;
			cursor: pointer;
			
			&:hover {
				transform: translateY(-0.8rem);
			}
		}
		
		svg {
			transition: transform 0.3s;
			cursor: pointer;

			&:hover {
				transform: translateY(-0.8rem);
			}
		}
	}
}

.svg-fade {
	opacity: 0.4
}