@import 'mixins';

.section4 {
  margin-top: 0;

  @media only screen and (max-width: 1100px) {
    margin-top: 5.2rem;
    margin-bottom: 0;
  }

  &__image-box {
    display: flex;
    justify-content: center;

    max-width: 100vw;
    // max-height: 58.7rem;
    transition: all 100ms;
    margin: 180px 0 100px 0;

    @media screen and (max-width: 1000px) {
      margin: 100px 0 70px 0;
    }

    @media screen and (max-width: 850px) {
      margin: 50px 0 80px 0;
    }

    @media screen and (max-width: 650px) {
      margin: 20px 0 80px 0;
    }

    @media screen and (max-width: 500px) {
      margin: 0 0 80px 0;
    }

    // @include responsive-on-width(1024px) {
    // 	display: none;
    // }

    // @include mobileLandscape {
    // 	display: none;
    // }

    @include responsive-on-width(480px) {
      display: unset;
    }
  }

  &__header {
    margin: 0 0 6.2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1100px) {
      margin: 3rem 4.2rem;
    }

    // @include responsive-on-width(580px) {
    // 	max-width: 30.9rem;
    // }

    h3 {
      color: #e7e7e7;
      font-weight: 600;
      font-size: 48px;
      line-height: 57.26px;
      margin-bottom: 3.1rem;

      @media only screen and (max-width: 1100px) {
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 4.3rem;
        text-align: center;
        color: #fff;
        margin-bottom: 2rem;
      }

      @media only screen and (max-width: 300px) {
        word-break: break-all;
      }

      // @media only screen and (max-width: 950px) {
      // 	margin-bottom: 1rem;
      // }

      // @media only screen and (max-width: 760px) {
      // 	font-size: 4.2rem;
      // 	line-height: 4.2rem;
      // }

      // @media only screen and (max-width: 680px) {
      // 	font-weight: 100;
      // 	font-size: 3.1rem;
      // 	line-height: 3.2rem;
      // }

      // @media only screen and (max-width: 580px) {
      // 	font-weight: 100;
      // 	font-size: 2.1rem;
      // 	line-height: 2.2rem;
      // 	text-align: left;
      // }
    }

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;

      @include responsive-on-width(1100px) {
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 2rem;
        color: rgba(231, 231, 231, 0.6);
      }
    }
  }

  &__features {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 3.7rem;

    .box--a {
      @media screen and (max-width: 980px) {
        grid-column: 1;
        grid-row: 1;
        transform: none !important;
      }
    }

    .box--b {
      @media screen and (max-width: 980px) {
        grid-column: span 2;
        width: 50%;
        transform: none !important;
      }
      @media only screen and (max-width: 780px) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 980px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      justify-items: center;
      padding: 0 4.2rem;
    }
    @media only screen and (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
    }
    @media only screen and (max-width: 380px) {
      padding: 0 20px;
    }
    .feature {
      .feature__info {
        > h5 {
          @media screen and (max-width: 385px) {
            word-break: break-all;
          }
        }
        @media screen and (max-width: 980px) {
          max-width: 100%;
          padding: 36px;
        }
        @media screen and (max-width: 385px) {
          padding: 36px 25px;
        }
        @media screen and (max-width: 355px) {
          padding: 36px 20px;
        }
      }
    }
  }

  &__code {
    display: flex;
  }

  &__safeLogo {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__rotation {
    position: absolute;
  }

  &__code-logo {
    display: none;
  }

  // &__lock {
  // 	@include responsive-on-width(1024) {
  // 		display: none;
  // 	}

  // 	@include mobileLandscape {
  // 		display: none;
  // 	}

  // 	@include responsive-on-width(480px) {
  // 		display: unset;
  // 	}
  // }
}

.feature {
  max-width: 35.9rem;
  background: linear-gradient(180deg, #6141d5 0%, rgba(93, 65, 213, 0.38) 100%);
  border-radius: 10px;
  transition: all 0.8s;

  @include responsive-on-width(1000px) {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  @include responsive-on-width(355px) {
    max-width: 100%;
  }

  &:hover {
    background: linear-gradient(
      180deg,
      #3b1da6 0%,
      rgba(93, 65, 213, 0.38) 100%
    );
  }

  // &:hover &__info p,
  // &:hover &__info h5 {
  // 	color: #000;
  // }

  &--msg {
    position: relative;

    &::before {
      content: attr(data-value);
      position: absolute;
      top: 1.9rem;
      right: 2.6rem;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      padding: 2px 6px;
      border-radius: 8px;
      background-color: #000;
      display: inline;
      color: #fff;

      @include responsive-on-width(980px) {
        right: unset;
        left: 2.9rem;
        top: 4rem;
        transform: translateY(-50%);
      }
    }
  }

  &--smile {
    position: relative;

    &::after {
      content: '😀';
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: 1.5rem;
      left: 3.1rem;
    }
  }

  &--dark {
    // background: linear-gradient(180deg, #3B1DA6 0%, rgba(93, 65, 213, 0.38) 100%);
  }

  &--down {
    margin-top: 8.6rem;

    @include responsive-on-width(980px) {
      margin-top: unset;
    }
  }

  &__info {
    // adjust card padding here
    padding: 5.2rem 3.1rem 3.5rem 3.1rem;
    max-width: 29.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    @include responsive-on-width(980px) {
      padding: 7.209rem 3rem 4rem 3rem;
      gap: 1.4rem;
    }

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28.63px;
      color: #fff;

      @include responsive-on-width(980px) {
        font-size: 3.2rem;
        line-height: 3.8rem;
        color: rgba(255, 255, 255, 1);
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.24px;
      color: rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 980px) {
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 2.2rem;
      }
    }
  }
}
