.modal {
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 5px;
	max-width: 50%;
	padding: 6rem 2rem 2rem 2rem;
	position: fixed;
	left: 50%;
	// top: 20%;
	// bottom: -1%;
	transform: translate(-50%, 0);
	overflow-y: auto;
	// transform: translateY(100%);
	z-index: 900;

	@mixin responsiveTablet {
		@media only screen and (max-width: 980px) {
			max-width: 90vw;
			width: 90vw;
		}
	}

	@include responsiveTablet();

	&--close {
		cursor: pointer;
		color: #1a1717;
		transition: all ease-in 200ms;
		position: absolute;
		top: 2%;
		right: 2.5rem;

		&:hover {
			color: #6c4098;
		}

		&:active {
			scale: 0.7;
		}

		&:after {
			display: inline-block;
			content: "\00d7";
			scale: 2.5;
			font-size: 1.5rem;
		}
	}

	&__body {
		// overflow-x: hidden;
		word-wrap: break-word;
		overflow-y: scroll;
		width: 100%;
		height: 100%;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.modal {
		-webkit-backdrop-filter: blur(35px);
		backdrop-filter: blur(35px);
		background-color: rgba(255, 255, 255, 0.5);
	}
}

.hide {
	animation-name: slide-out;
	animation-duration: 800ms;
	animation-fill-mode: forwards;
}

.show {
	animation-name: slide-in;
	animation-duration: 800ms;
	animation-fill-mode: forwards;
}

@keyframes slide-out {
	0% {
		opacity: 100;
		top: 20%;
		bottom: -1%;
	}

	100% {
		opacity: 0;
		top: 101%;
		bottom: -3%;
	}
}

@keyframes slide-in {
	0% {
		opacity: 0;
		top: 101%;
		bottom: -3%;
	}

	100% {
		opacity: 100;
		top: 20%;
		bottom: -1%;
	}
}

.no-scroll {
	overflow-y: hidden !important;
}
