@import 'mixins';

.footer {
	color: #fff;

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include responsive-on-width(1100px) {
			justify-content: center;
		}
	}

	// &__img {
	// 	&-chat {
	// 		@media only screen and (max-width: 980px) {
	// 			width: 40px
	// 		}
	// 	}
	// }

	&__links {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 5.9rem;

		@include responsive-on-width(1100px) {
			justify-content: center;
		}

		&--pack {
			display: flex;
			gap: 2.9rem;

			@include responsive-on-width(1100px) {
				flex-direction: row;
			}
		}

		&--pack-b {
			display: flex;
			justify-content: center;
			gap: 2.9rem;

			@include responsive-on-width(1100px) {
				margin-top: 2.531rem;
			}
	
		}

		// @media only screen and (max-width: 980px) {
		// 	align-items: baseline;

		// 	.button__secondary {
		// 		padding: 1rem 2rem;
		// 	}
		// }

		// @media only screen and (max-width: 300px) {
		// 	flex-direction: column-reverse;
		// 	gap: 2rem
		// }

		ul {
			display: flex;
			gap: 2.9rem;
			list-style-type: none;

			@media only screen and (max-width: 1100px) {
				display: unset;
			}
		}

		ul li a {
			font-size: 1.782rem;
			color: #fff;
			line-height: 2.126rem;
		}
	}

	&__sitemaps {
		display: flex;
		justify-content: space-between;
		margin-top: 4.331rem;

		@include responsive-on-width(1100px) {
			margin-left: 3.4rem;
			margin-right: 3.4rem;
			margin-bottom: 4.4rem;
		}


		ul {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;
			list-style-type: none;
		}

		ul li {

			a,
			span {
				font-size: 1.5rem;
				line-height: 1.8rem;
				color: rgba(255, 255, 255, 0.6);
				cursor: pointer;
			}

			&:last-child {
				display: inline-block;

				@include responsive-on-width(1100px) {
					display: none;
				}

				span {
					color: var(--color-white)
				}
			}
		}
	}

	&__CTA {
		// font-size: 1.5rem;
		line-height: 1.8rem;
		color: rgba(255, 255, 255, 1);
		cursor: pointer;
		// align-self: end;
		display: flex;
		flex-direction: column;
		gap: 1.2rem;

		@include responsive-on-width(1100px) {
			// font-size: unset;
		}

		li {
			list-style-type: none;
			width: max-content;

			span {
				font-size: 1.4rem;
			}
		}

		a {
			color: rgba(255, 255, 255, 1);
			font-size: 1.4rem;
			line-height: 1.6rem;

			@include responsive-on-width(1100px) {
				text-decoration: underline;
				align-self: flex-end;
			}
		}
	}

	.button__secondary {
		background: 0;
	}

	&__actions {
		display: none;

		@include responsive-on-width(1100px) {
			margin: 4.465rem 0 3.9rem 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2.5rem;
		}
	}
}