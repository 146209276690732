@import 'mixins';

.section1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 16.1rem 0;
	width: 100%;
	overflow: hidden;

	@include responsive-on-width(1290px) {
		margin: 0;
	}

	&--items {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include responsive-on-width(1100px) {
			display: unset;
		}
	}

	&__img {
		// height: auto;
		// max-width: 100%;

		// width: 239.79px;
		// max-height: 486px;
	}

	&__images {
		object-fit: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		// gap: 6rem;
		// margin-left: auto;
		transform: translateX(16%);

		@include responsive-on-width(1100px) {
			transform: translateX(7%);
		}

		img {
			width: 80%;

			@include responsive-on-width(1100px) {
				max-height: 48.6rem;
			}

			@include responsive-on-width(479px) {
				width: 100%;
			}
		}

		// @media only screen and (max-width: 1100px) { soon
		// 	display: inline-block;
		// 	width: 100%;
		// 	transform: unset;
		// 	img {
		// 		width: 100%;
		// 		margin-left: 2.6rem;
		// 	}
		// }

		// @include responsive-on-width(350px) {
		// 	img {
		// 		width: 75%;
		// 		margin-left: unset;
		// 	}
		// }

		// @media (max-width: 1290px) {
		// 	width: 100%;
		// 	// max-height: 400px;
		// }
	}

	&__info {
		margin-top: 15rem;
		color: #fff;
		width: 52%;
		// margin-left: 20rem;

		@media (max-width: 1100px) {
			@include center-flex-column;
			gap: 2rem;
			width: 100%;
			padding: 4.2rem;
			padding-bottom: 0;
			margin-top: 7.7rem;
		}

		// @media (max-width: 760px) {
		// 	width: 100%;
		// }

		.button__primary {
			margin-top: 0;
			display: none;

			@include responsive-on-width(1100px) {
				display: flex;
			}

			@include responsive-on-width(330px) {
				max-width: 80%;
			}
		}
	}

	&__heading {
		font-weight: 600;
		font-size: 4.8rem;
		line-height: 5.7rem;
		text-align: center;
		padding-bottom: 5rem;

		@include responsive-on-width(1100px) {
			padding: 0;
			font-size: 3.6rem;
			line-height: 4.295rem;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 2.4rem;
		line-height: 2.8rem;
		text-align: center;
		color: rgba(255, 255, 255, 0.5);

		@include responsive-on-width(1100px) {
			font-weight: 400;
			font-size: 1.7rem;
			line-height: 2rem;
		}

		@include responsive-on-width(330px) {
			max-width: 90%;
		}
	}
}

.image-box {
	margin: 0;
	max-width: max-content;
	// display: flex;
	justify-content: center;


	@include responsive-on-width(479px) {
		max-width: unset;
		transform: translateX(8%);
		&:first-child {
			display: none;
		}
	}
}